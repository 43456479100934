import { useTranslation } from 'react-i18next'

import MyMarkdown from './Markdown.tsx'
import { Tooltip } from './Tooltip.tsx'

export const Header = (props: { slogan: boolean; maintenance?: boolean }) => {
  const { t } = useTranslation()
  return (
    <header className="header">
      <div className="container">
        <div className="header__text">
          <h1 className="h1">{t('header.title')}</h1>
          {props.slogan === true && (
            <div className="slogan ">
              <MyMarkdown markdown={t('header.slogan')} />
            </div>
          )}
          {props.maintenance && (
            <div style={{ backgroundColor: 'var(--color-primary-100)', padding: '1em' }}>
              {' '}
              <h3>Maintenance Mode</h3>
              <div>{import.meta.env.VITE_SITE_NAME} is currently undergoing maintenance. Please come back later.</div>
            </div>
          )}
        </div>
      </div>
      <Tooltip />
    </header>
  )
}
